<template lang="pug">
        <!--  BEGIN NAVBAR  -->
        div.header-container
            header.header.navbar.navbar-expand-sm
                a.sidebarCollapse(href="javascript:void(0);" data-placement="bottom")
                    svg.feather.feather-menu(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                        line(x1="3" y1="12" x2="21" y2="12")
                            line(x1="3" y1="6" x2="21" y2="6")
                                line(x1="3" y1="18" x2="21" y2="18")

                div.nav-logo.align-self-center
                    router-link.navbar-brand(to="/content/contenthome")
                        img(alt="logo" src="../../assets/img2/icon_logo.png")
                        span.navbar-brand-name insigne

                ul.navbar-item.topbar-navigation
                    
                    <!--  BEGIN TOPBAR  -->
                    div.topbar-nav.header.navbar(role="banner")
                        nav(id="topbar")
                            ul.navbar-nav.theme-brand.flex-row.text-center
                                li.nav-item.theme-logo
                                    a(href="index.html")
                                        img.navbar-logo(src="../../assets/img2/icon_logo.png" alt="logo")
                                li.nav-item.theme-text
                                    router-link.nav-link(to="/content/contenthome") insigne

                            ul.list-unstyled.menu-categories(id="topAccordion")
                                li.menu.active
                                    router-link(to="/content/contenthome")
                                        div
                                            svg.feather.feather-home(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                path(d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z")
                                                polyline(points="9 22 9 12 15 12 15 22")
                                            svg.feather.feather-home.shadow-icons(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                path(d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z")
                                                polyline(points="9 22 9 12 15 12 15 22")
                                            span Dashboard
                                

                    <!--  END TOPBAR  -->

                ul.navbar-item.flex-row.ml-auto.nav-dropdowns
                    li.nav-item.dropdown.notification-dropdown
                        a.nav-link.dropdown-toggle(href="javascript:void(0);" id="notificationDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                            svg.feather.feather-bell(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                path(d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9")
                                path(d="M13.73 21a2 2 0 0 1-3.46 0")
                            span.badge.badge-success

                        div.dropdown-menu.position-absolute.animated.fadeInUp(aria-labelledby="notificationDropdown")
                            div.notification-scroll
                                div.dropdown-item
                                    div.media.server-log
                                        svg.feather.feather-bell(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                            path(d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9")
                                            path(d="M13.73 21a2 2 0 0 1-3.46 0")
                                        div.media-body
                                            div.data-info
                                                h6 Notificación 2
                                                p 45 min

                                            div.icon-status
                                                svg.feather.feather-x(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                    line(x1="18" y1="6" x2="6" y2="18")
                                                    line(x1="6" y1="6" x2="18" y2="18")
                                div.dropdown-item
                                    div.media.server-log
                                    svg.feather.feather-bell(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                        path(d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9")
                                        path(d="M13.73 21a2 2 0 0 1-3.46 0")
                                    div.media-body
                                        div.data-info
                                            h6 Notificación 1
                                            p 8 hrs

                                        div.icon-status
                                            svg.feather.feather-x(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                line(x1="18" y1="6" x2="6" y2="18")
                                                line(x1="6" y1="6" x2="18" y2="18")

                    li.nav-item.dropdown.user-profile-dropdown.order-lg-0.order-1
                        a.nav-link.dropdown-toggle.user(href="javascript:void(0);" id="user-profile-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                            div.media
                                img.img-fluid(src="../../assets/img2/90x90.jpg" alt="admin-profile")
                        div.dropdown-menu.position-absolute.animated.fadeInUp(aria-labelledby="userProfileDropdown")
                            div.user-profile-section
                                div.media.mx-auto
                                    img.img-fluid.mr-2(src="../../assets/img2/90x90.jpg" alt="avatar")
                                    div.media-body
                                        h5 {{ name }}
                                        p Administrador

                            div.dropdown-item
                                a(href="user_profile.html")
                                    svg.feather.feather-user(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                        path(d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2")
                                        circle(cx="12" cy="7" r="4")
                                    span Mi cuenta

                            div.dropdown-item
                                a(href="apps_mailbox.html")
                                    svg.feather.feather-settings(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                        circle(cx="12" cy="12" r="3")
                                        path(d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z")
                                    span Configuración
                        
                            div.dropdown-item
                                router-link(to="/" @click.native="logout()")
                                    svg.feather.feather-log-out(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                        path(d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4")
                                        polyline(points="16 17 21 12 16 7")
                                        line(x1="21" y1="12" x2="9" y2="12")
                                    span Cerrar sesión

</template>

<script>
export default {
    name: 'HeaderComponent',
    data() {
        return {
            name: JSON.parse(localStorage.getItem("dataPerson")).sName + ' ' + JSON.parse(localStorage.getItem("dataPerson")).sLastName
        }
    }
}
</script>